export enum Browsers {
  CHROME = "chrome",
  FIREFOX = "firefox",
  SAFARI = "safari",
  OPERA = "opera",
  EDGE = "edge",
  MISC = "Misc",
}

export interface OtpResponse {
  code: number;
  message: string;
  accessToken: string;
  userData: {
    _id: string;
    username: string;
    name: string;
    profilePic: string;
  };
}

export enum REQUEST_METHOD {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export interface AuthType {
  token?: string;
  userId?: string;
}

export enum USER_TYPE {
  USER = "USER",
  CREATOR = "CREATOR",
  CONSUMER = "CONSUMER",
}

export interface ProfileDataInterface {
  accountStatus: string;
  age: number;
  badgeStatus: number;
  countryCode: string;
  dob: string;
  followers: number;
  following: number;
  isFollowed: boolean;
  isFollowing: boolean;
  gariPubKey: string;
  gender: number;
  isFeatured: boolean;
  isKYCDone: number;
  isProfileVerified: number;
  isUserVotedForBadge: boolean;
  location: string;
  name: string;
  postCount: number;
  preferredLanguage: string[];
  profilePic: string;
  status: string;
  tipEnabled: number;
  username: string;
  voteReceived: number;
  profileType: {
    name: USER_TYPE;
  };
  diamonds: number;
  beans: number;
  level: number;
  creatorLevel: number;
  earningsPerMinute: number;
  pricePerMinute: number;
  privateCallLevel: number;
}

export interface ToastDataType {
  type?: ToastType;
  message?: string;
  visible?: boolean;
}

export interface PopupType {
  show: boolean;
  onClose: () => void;
}

export interface getDiamondPlansResponse {
  _id: string;
  source: number;
  purchaseOption: string;
  planImage: string;
  planHighlightCopy: string;
  planHighlight: boolean;
  showDiscount: boolean;
  status: number;
  sku: string;
  name: string;
  type: number;
  description: string;
  title: string;
  planId: string;
  diamonds: number;
  availableOn: number;
  costPrice: number;
  salePrice: number;
  createdAt: string;
  updatedAt: string;
  gariEnabledPurchase: number;
}

export interface getDiamondPlansResponseData {
  plans: getDiamondPlansResponse[];
}

export enum ESessionPaymentType {
  FREE = "FREE",
  PAID = "PAID",
}

export interface MessageData {
  body?: string; // Required for "Default"
  urls?: string[]; // Required for "Attachment"
  raw?: Record<string, any>; // Required for "Onboarding" or "Gift"
}

export enum paidDMMsgType {
  DEFAULT = "Default",
  ATTACHMENT = "Attachment",
  ONBOARDING = "Onboarding",
  GIFT = "Gift",
}
export interface EmitMessageParams {
  type?: paidDMMsgType;
  data: MessageData;
  roomId: string;
}

export enum callPopupENUM {
  CALL = "call",
  CHAT = "chat",
}

export enum ToastType {
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
}
